const driverRouter = {
  path: '/driver',
  meta: {
    title: 'Driver',
    icon: 'driver'
  },
  children: [
    {
      path: 'manage-driver',
      component: () => import('@/views/driver/ManageDriver/manage-driver'),
      name: 'ManageDriver',
      meta: { title: 'ManageDriver', icon: 'submenu' }
    },
    {
      path: 'manage-driver-form/:id?',
      component: () => import('@/views/driver/ManageDriver/manage-driver-form'),
      name: 'ManageDriverForm',
      hidden: true,
      meta: { title: 'ManageDriverForm', icon: 'submenu', activeMenu: '/driver/manage-driver' }
    },
    {
      path: 'driver-details/:id',
      component: () => import('@/views/driver/ManageDriver/driver-details'),
      name: 'DriverDetails',
      hidden: true,
      meta: { title: 'DriverDetails', icon: 'submenu', activeMenu: '/driver/manage-driver' }
    },
    // {
    //   path: 'driver-inquiry',
    //   component: () => import('@/views/driver/DriverInquiry/driver-inquiry'),
    //   name: 'DriverInquiry',
    //   meta: { title: 'DriverInquiry', icon: 'submenu' }
    // },
    // {
    //   path: 'driver-app',
    //   name: 'DriverApp',
    //   meta: { title: 'DriverApp', icon: 'submenu' },
    //   children: [
    //     {
    //       path: 'check-in-out',
    //       component: () => import('@/views/driver/DriverApp/check-in-out'),
    //       name: 'CheckInOut',
    //       meta: { title: 'CheckInOut', icon: 'subsubmenu' }
    //     },
    //     {
    //       path: 'checklist-approval',
    //       component: () => import('@/views/driver/DriverApp/checklist-approval'),
    //       name: 'ChecklistApproval',
    //       meta: { title: 'ChecklistApproval', icon: 'subsubmenu' }
    //     },
    //     {
    //       path: 'driver-claim-report',
    //       component: () => import('@/views/driver/DriverApp/driver-claim-report'),
    //       name: 'DriverClaimReport',
    //       meta: { title: 'DriverClaimReport', icon: 'subsubmenu' }
    //     },
    //     {
    //       path: 'driver-tracing',
    //       component: () => import('@/views/driver/DriverApp/driver-tracing'),
    //       name: 'DriverTracing',
    //       meta: { title: 'DriverTracing', icon: 'subsubmenu' }
    //     }
    //   ]
    // }
  ]
}

export default driverRouter
