import { createRouter, createWebHistory } from 'vue-router'

import fuelManagementRouter from '@/router/modules/fuel-management'
import vehicleRouter from '@/router/modules/vehicle'
import fleetAccessSettingsRouter from '@/router/modules/fleet-access-settings'
import GPSTrackingRouter from '@/router/modules/gps-tracking'
import driverRouter from '@/router/modules/driver'


export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/Login/login'),
    name: 'LOGIN',
    meta: {
      bypass: true
    },
    hidden: true
  },
  {
    path: '/',
    component: () => import('@/views/KPIDashboard/kpi-dashboard'),
    name: 'KPIDashboard',
    meta: { title: 'KPIDashboard', icon: 'dashboard' }
  },
  GPSTrackingRouter,
  vehicleRouter,
  driverRouter,
  fuelManagementRouter,
  fleetAccessSettingsRouter,
  {
    path: '/vehicle-certificate',
    name: 'VehicleCertificate',
    component: () => import('@/certificate/vehicle-certificate.vue'),
    hidden: true
  },
  {
    path: '/401',
    name: 'error-401',
    component: () => import('@/views/ErrorPage/401-Error.vue'),
    hidden: true
  },
  {
    path: '/404',
    name: 'error-404',
    component: () => import('@/views/ErrorPage/404-Error.vue'),
    hidden: true
  }
]

export const asyncRoutes = [

]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BUILD_PATH),
  // history: createWebHistory(),
  routes: constantRoutes
})

export default router
